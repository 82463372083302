export const getFileType = (type: any) => {
    if (type == 'image/jpeg') {
        return 'JPG'
    } else if (type == 'image/png') {
        return 'PNG'
    } else if (type == 'application/pdf') {
        return 'PDF'
    } else if (
        type ==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
        return 'DOCX'
    } else if (
        type ==
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
        return 'XLSX'
    } else {
        return type
    }
}
