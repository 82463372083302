import { useState, useEffect, useCallback } from 'react'
import CalendarSmall from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import { AvailService } from '../_services/availability.services'
import './availability.css'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import * as Sentry from '@sentry/react'
import { differenceInDays, subDays, addDays, addMonths } from 'date-fns'
import { cloneDeep } from 'lodash'
import { useStores } from '../utils/stores'

const localizer = momentLocalizer(moment)

const CALENDAR_MONTHS_ALLOWED = 6

moment.locale('en-GB', {
    week: {
        dow: 1,
        doy: 1,
    },
})

function Availability() {
    const { coach } = useStores()
    const [value, setValue] = useState(new Date())
    const [myEventsList, setEventList] = useState([])
    const [availibilityList, setAvailibilityList] = useState([])
    const [date, setDefaultDate] = useState(new Date())
    const [isShow, setIsShow] = useState(false)
    const [isDeleteModal, setDeleteModal] = useState(false)
    const [startTime, setStartTime] = useState<any>()
    const [endTime, setEndTime] = useState<any>()
    const [reccurences, setReccurences] = useState([])

    const [deleteReccurence, setDeleteReccurence] = useState<any>({})
    const [delStartTime, setDelstartTime] = useState<any>()
    const [delEndTime, setDelEndTime] = useState<any>()
    const [showDelete, setShowDelete] = useState(false)
    const [open, setOpen] = useState(false)
    const [openend, setOpenEnd] = useState(false)
    const [isNext, setIsNext] = useState(false)
    const [isPrev, setIsPrev] = useState(true)
    const [eventSelected, setEventSelected] = useState<any>({})

    const lastAllowedDate = addMonths(new Date(), CALENDAR_MONTHS_ALLOWED)
    const id = JSON.parse(
        window.localStorage.getItem('userinfo') ?? '{}',
    )._id.toString()

    useEffect(() => {
        coach.getBookings().subscribe({
            next() {
                setAllEvents()
            },
        })
    }, [])

    const setAllEvents = async () => {
        const availabilityDates = await getAvailabilityDates()
        const bookingDates = getBookingDates()
        const busyDates = await getBusyDates()
        setAvailibilityList(availabilityDates)
        setEventList(availabilityDates.concat(bookingDates).concat(busyDates))
    }

    const getAvailabilityDates = async () => {
        return await AvailService.getAvailability(id).then((res: any) => {
            try {
                return res.map((item: any, ind: number) => {
                    const from = new Date(item.from)
                    if (from < lastAllowedDate) {
                        return {
                            id: ind + 1,
                            title: '',
                            start: from,
                            end: new Date(item.to),
                        }
                    }
                })
            } catch (error) {
                Sentry.captureException(error)
            }
        })
    }

    const getBookingDates = useCallback(() => {
        try {
            return coach.bookings.map((booking, ind) => {
                const date = new Date(booking.date)
                date.setMinutes(date.getMinutes() + booking.duration)

                return {
                    id: ind + 10000,
                    title: 'Session booked',
                    start: new Date(booking.date),
                    end: date,
                    isBooking: true,
                }
            })
        } catch (error) {
            Sentry.captureException(error)
        }
    }, [])

    const getBusyDates = async () => {
        return await AvailService.getBusyTimes(id).then((res: any) => {
            try {
                let dates: any = []
                res.forEach((item: any, key: number) => {
                    if (item.from < lastAllowedDate) {
                        if (
                            differenceInDays(item.to, item.from) >= 1 ||
                            item.to.getDate() !== item.from.getDate()
                        ) {
                            dates = dates.concat(
                                getDates(key, item.from, item.to),
                            )
                        } else {
                            dates.push({
                                id: key + 50000,
                                title: 'Busy',
                                start: item.from,
                                end: item.to,
                                isBusy: true,
                            })
                        }
                    }
                })
                return dates
            } catch (error) {
                Sentry.captureException(error)
                throw error
            }
        })
    }

    const getDates = (key: any, startDate: any, endDate: any) => {
        const dates = []
        let currentDate = startDate
        let count = 0
        while (currentDate <= endDate) {
            const endTime = cloneDeep(currentDate)
            endTime.setHours(23)
            endTime.setMinutes(59)
            dates.push({
                id: count + parseInt('51' + key + '000'),
                title: 'Busy',
                start: currentDate,
                end: endTime,
                isBusy: true,
            })

            currentDate = addDays(currentDate, 1)
            currentDate.setHours(0)
            currentDate.setMinutes(0)

            count++
        }
        dates.pop()

        if (subDays(currentDate, 1).getTime() !== endDate.getTime()) {
            dates.push({
                id: count + parseInt('51' + key + '000'),
                title: 'Busy',
                start: subDays(currentDate, 1),
                end: endDate,
                isBusy: true,
            })
        }

        return dates
    }

    const availSubmit = () => {
        const d = moment(value).format('YYYY-MM-DD')
        const start = moment(
            d + '' + startTime,
            'YYYY-MM-DDLTHH:mm:ss',
        ).toISOString()
        const end = moment(
            d + '' + endTime,
            'YYYY-MM-DDLTHH:mm:ss',
        ).toISOString()

        const obj = {
            toAddRange: { from: start, to: end },
            recurrence: reccurences,
        }

        AvailService.createAvailability(id, obj).then(() => {
            setAllEvents()
        })

        setReccurences([])
        setIsShow((isShow) => !isShow)
    }

    const handleStartTimeChange = (value: any) => {
        setStartTime(value.format('HH:mm'))
    }

    const handleEndTimeChange = (value: any) => {
        setEndTime(value.format('HH:mm'))
    }
    const delHandleStartTimeChange = (value: any) => {
        setDelstartTime(value.format('HH:mm'))
    }

    const cancelStartDateChange = () => {
        let originalStart = eventSelected.start
        setDelstartTime(moment(originalStart).format('HH:mm'))
        setOpen(false)
    }

    const cancelEndDateChange = () => {
        let originalStart = eventSelected.end
        setDelEndTime(moment(originalStart).format('HH:mm'))
        setOpenEnd(false)
    }

    const DelHandleEndTimeChange = (value: any) => {
        setDelEndTime(value.format('HH:mm'))
    }
    const deleteSubmit = () => {
        const d = moment(deleteReccurence.date).format('YYYY-MM-DD')
        const start = moment(
            d + '' + delStartTime,
            'YYYY-MM-DDLTHH:mm:ss',
        ).toISOString()
        const end = moment(
            d + '' + delEndTime,
            'YYYY-MM-DDLTHH:mm:ss',
        ).toISOString()

        const obj: any = {}
        if (showDelete && typeof deleteReccurence.recurrence !== 'undefined') {
            obj.toDeleteRange = { from: start, to: end }
            if (deleteReccurence.recurrence) {
                obj.recurrence = [moment(start).format('dddd').toLowerCase()]
            }
        } else {
            obj.toAddRange = { from: start, to: end }
        }

        obj.toDeleteRange = { from: eventSelected.start, to: eventSelected.end }

        AvailService.createAvailability(id, obj).then((data) => {
            setAllEvents()
        })

        setReccurences([])
        setShowDelete(false)
        setDeleteModal((isDeleteModal) => !isDeleteModal)
    }

    const handleSelect = ({ start }: any) => {
        const date = new Date(start)
        if (date < lastAllowedDate && date >= new Date()) {
            setValue(date)
            setStartTime(moment(start).format('H:mm'))
            setEndTime(moment(start).add(0.5, 'hour').format('H:mm'))

            setIsShow((isShow) => !isShow)
        }
    }

    const getScrollToDate = () => {
        const newDate = new Date()
        newDate.setHours(7)
        return newDate
    }
    const onSelectRec = (e: any) => {
        let recList = reccurences
        // @ts-ignore
        if (recList.indexOf(e.target.value) === -1) recList.push(e.target.value)
        // @ts-ignore
        else recList.splice(recList.indexOf(e.target.value), 1)
        setReccurences(recList)
    }

    const eventSelect = ({
        start,
        end,
        isBooking,
        isBusy,
    }: {
        start: any
        end: any
        isBooking: any
        isBusy: any
    }) => {
        if (isBooking || isBusy) {
            return true
        } else {
            setDeleteReccurence({
                start: moment(start).format('H:mm'),
                end: moment(end).format('H:mm'),
                date: start,
            })
            setEventSelected({ start, end, isBooking, isBusy })
            setDelEndTime(moment(end).format('H:mm'))
            setDelstartTime(moment(start).format('H:mm'))
            setDeleteModal((isDeleteModal) => !isDeleteModal)
        }
        return false
    }

    const navigateCallback = (focusDate: any) => {
        setDefaultDate(focusDate)
        setValue(focusDate)
        setIsNext(focusDate >= lastAllowedDate)
        setIsPrev(focusDate < new Date())
    }

    const setEventStyle = (event: any) => {
        if (event.isBooking) {
            return { className: 'calendar-booking' }
        } else if (event.isBusy) {
            return { className: 'calendar-busy-time' }
        }
    }

    const formatWeekDay = (date: any) => {
        return ['S', 'M', 'T', 'W', 'T', 'F', 'S'][date.getDay()] + '\n'
    }
    const maxDate = lastAllowedDate

    return (
        <section>
            <div className="calendar-container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="text-primary">My availability</h2>
                        <div className="clearfix">
                            <p className="sub-text pull-left">
                                Simply select time and date for coaching
                                sessions availability
                            </p>
                            <div className="pull-right box-split">
                                <div>
                                    Availability{' '}
                                    <span className="box-availability"></span>
                                </div>
                                <div>
                                    Booked session{' '}
                                    <span className="box-booked-session"></span>
                                </div>
                                <div>
                                    Busy times in synced calendar{' '}
                                    <span className="box-busy-times"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isNext ? 'row disable-next' : 'row'}>
                    <div className="col-md-4 date-calender">
                        <CalendarSmall
                            onChange={(param: any) => setDefaultDate(param)}
                            value={value}
                            minDetail={'month'}
                            showNavigation={true}
                            showNeighboringMonth={false}
                            minDate={new Date()}
                            maxDate={maxDate}
                            next2Label={null}
                            prev2Label={null}
                        />
                    </div>

                    <div
                        className={
                            isPrev
                                ? 'col-md-8 large-calender disable-prev'
                                : 'col-md-8 large-calender'
                        }
                    >
                        <Calendar
                            selectable
                            date={date}
                            localizer={localizer}
                            defaultDate={date}
                            events={myEventsList}
                            backgroundEvents={availibilityList}
                            startAccessor="start"
                            endAccessor="end"
                            defaultView="week"
                            // @ts-ignore
                            eventPropGetter={setEventStyle}
                            views={['week']}
                            style={{ height: 700 }}
                            onSelectEvent={eventSelect}
                            onSelectSlot={handleSelect}
                            scrollToTime={getScrollToDate()}
                            formats={{
                                timeGutterFormat: (
                                    date: any,
                                    culture: any,
                                    localizer: any,
                                ) => localizer.format(date, 'HH:mm ', culture),
                                dayFormat: (
                                    date: any,
                                    culture: any,
                                    localizer: any,
                                ) =>
                                    formatWeekDay(date) +
                                    localizer.format(date, 'DD', culture),
                            }}
                            onNavigate={(
                                focusDate: any,
                                flipUnit: any,
                                prevOrNext: any,
                            ) => navigateCallback(focusDate)}
                        />
                    </div>
                </div>
            </div>
            {isShow && (
                <div className="modal modal-lg" id="videoModal">
                    <div className="modal-dialog modal-center">
                        <div className="modal-content avail-slots">
                            <div className="modal-header"></div>

                            <div className="modal-body">
                                <h6 className="title">Select availability</h6>
                                <div className="date-text text-primary">
                                    {moment(value).format('DD') !==
                                    moment(new Date()).format('DD')
                                        ? moment(value).format('dddd')
                                        : 'Today'}
                                    , {moment(value).format('MMMM Do')}
                                </div>
                                <div className="clearfix">
                                    <div className="pull-left">
                                        <div className="time-text">
                                            Start time
                                        </div>
                                        <div className="time-text">
                                            End time
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                        <div className="time-text">
                                            <TimePicker
                                                value={moment(
                                                    startTime,
                                                    'HH:mm',
                                                )}
                                                open={open}
                                                onOpen={() => setOpen(!open)}
                                                onClose={() => setOpen(!open)}
                                                onChange={handleStartTimeChange}
                                                showSecond={false}
                                                // clearIcon={" "}
                                                addon={() => (
                                                    <div className="date-picker-bottom">
                                                        <span
                                                            onClick={() =>
                                                                cancelStartDateChange()
                                                            }
                                                        >
                                                            Cancel
                                                        </span>
                                                        <span
                                                            onClick={() =>
                                                                setOpen(false)
                                                            }
                                                        >
                                                            OK
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                            <span
                                                className="fa fa-pencil"
                                                onClick={() => setOpen(!open)}
                                            ></span>
                                        </div>
                                        <div className="time-text">
                                            <TimePicker
                                                value={moment(endTime, 'HH:mm')}
                                                open={openend}
                                                onOpen={() =>
                                                    setOpenEnd(!openend)
                                                }
                                                onClose={() =>
                                                    setOpenEnd(!openend)
                                                }
                                                onChange={handleEndTimeChange}
                                                showSecond={false}
                                                clearIcon={' '}
                                                addon={() => (
                                                    <div className="date-picker-bottom">
                                                        <span
                                                            onClick={() =>
                                                                cancelEndDateChange()
                                                            }
                                                        >
                                                            Cancel
                                                        </span>
                                                        <span
                                                            onClick={() =>
                                                                setOpenEnd(
                                                                    false,
                                                                )
                                                            }
                                                        >
                                                            OK
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                            <span
                                                className="fa fa-pencil"
                                                onClick={() =>
                                                    setOpenEnd(!openend)
                                                }
                                            ></span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="text-primary time-text mt-3">
                                        Repeat days
                                    </div>
                                    <div className="reccurence-slots">
                                        <div className="round">
                                            <input
                                                type="checkbox"
                                                id="monday"
                                                value="monday"
                                                onChange={onSelectRec}
                                            />
                                            <label htmlFor="monday">M</label>
                                        </div>
                                        <div className="round">
                                            <input
                                                type="checkbox"
                                                id="tuesday"
                                                value="tuesday"
                                                onChange={onSelectRec}
                                            />
                                            <label htmlFor="tuesday">T</label>
                                        </div>
                                        <div className="round">
                                            <input
                                                type="checkbox"
                                                id="wednesday"
                                                value="wednesday"
                                                onChange={onSelectRec}
                                            />
                                            <label htmlFor="wednesday">W</label>
                                        </div>
                                        <div className="round">
                                            <input
                                                type="checkbox"
                                                id="thursday"
                                                value="thursday"
                                                onChange={onSelectRec}
                                            />
                                            <label htmlFor="thursday">T</label>
                                        </div>
                                        <div className="round">
                                            <input
                                                type="checkbox"
                                                id="friday"
                                                value="friday"
                                                onChange={onSelectRec}
                                            />
                                            <label htmlFor="friday">F</label>
                                        </div>
                                        <div className="round">
                                            <input
                                                type="checkbox"
                                                id="saturday"
                                                value="saturday"
                                                onChange={onSelectRec}
                                            />
                                            <label htmlFor="saturday">S</label>
                                        </div>
                                        <div className="round">
                                            <input
                                                type="checkbox"
                                                id="sunday"
                                                value="sunday"
                                                onChange={onSelectRec}
                                            />
                                            <label htmlFor="sunday">S</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-popup"
                                    onClick={() => setIsShow(!isShow)}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-100 btn-primary"
                                    onClick={availSubmit}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {isDeleteModal && (
                <div className="modal modal-lg" id="deleteModal">
                    <div className="modal-dialog modal-center">
                        <div className="modal-content avail-slots">
                            <div className="modal-header"></div>

                            <div className="modal-body">
                                <div className="date-text text-primary mt-3 mb-4">
                                    {moment(deleteReccurence.date).format(
                                        'YYYY-MM-DD',
                                    ) ===
                                    moment(new Date()).format('YYYY-MM-DD')
                                        ? 'Today'
                                        : moment(deleteReccurence.date).format(
                                              'dddd',
                                          )}
                                    ,{' '}
                                    {moment(deleteReccurence.date).format(
                                        'MMMM Do',
                                    )}
                                </div>
                                <div className="clearfix">
                                    <div className="pull-left">
                                        <div className="time-text">
                                            Start time
                                        </div>
                                        <div className="time-text">
                                            End time
                                        </div>
                                    </div>
                                    <div className="pull-right">
                                        <div className="time-text">
                                            <TimePicker
                                                className="availability-time-picker"
                                                value={moment(
                                                    delStartTime,
                                                    'HH:mm',
                                                )}
                                                open={open}
                                                onOpen={() => setOpen(!open)}
                                                onClose={() => setOpen(!open)}
                                                onChange={
                                                    delHandleStartTimeChange
                                                }
                                                showSecond={false}
                                                clearIcon={' '}
                                                addon={() => (
                                                    <div className="date-picker-bottom">
                                                        <span
                                                            onClick={() =>
                                                                cancelStartDateChange()
                                                            }
                                                        >
                                                            Cancel
                                                        </span>
                                                        <span
                                                            onClick={() =>
                                                                setOpen(false)
                                                            }
                                                        >
                                                            OK
                                                        </span>
                                                    </div>
                                                )}
                                            />

                                            <span
                                                className="fa fa-pencil"
                                                onClick={() => setOpen(!open)}
                                            ></span>
                                        </div>
                                        <div className="time-text">
                                            <TimePicker
                                                value={moment(
                                                    delEndTime,
                                                    'HH:mm',
                                                )}
                                                open={openend}
                                                onOpen={() =>
                                                    setOpenEnd(!openend)
                                                }
                                                onClose={() =>
                                                    setOpenEnd(!openend)
                                                }
                                                onChange={
                                                    DelHandleEndTimeChange
                                                }
                                                showSecond={false}
                                                clearIcon={' '}
                                                addon={() => (
                                                    <div className="date-picker-bottom">
                                                        <span
                                                            onClick={() =>
                                                                cancelEndDateChange()
                                                            }
                                                        >
                                                            Cancel
                                                        </span>
                                                        <span
                                                            onClick={() =>
                                                                setOpenEnd(
                                                                    false,
                                                                )
                                                            }
                                                        >
                                                            OK
                                                        </span>
                                                    </div>
                                                )}
                                            />
                                            <span
                                                className="fa fa-pencil"
                                                onClick={() =>
                                                    setOpenEnd(!openend)
                                                }
                                            ></span>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div
                                        className="text-primary clearfix mt-3 delete-row"
                                        onClick={() =>
                                            setShowDelete(!showDelete)
                                        }
                                    >
                                        <span className="pull-left time-text ">
                                            Delete
                                        </span>
                                        <span className="pull-right fa fa-trash trash-icon"></span>
                                    </div>
                                    {showDelete && (
                                        <div className="del-reccurence-slots">
                                            <div className="del-reccurence-slots">
                                                <input
                                                    type="radio"
                                                    id="del-avail"
                                                    name="delete-rec"
                                                    onChange={() =>
                                                        setDeleteReccurence({
                                                            ...deleteReccurence,
                                                            recurrence: false,
                                                        })
                                                    }
                                                />
                                                <label htmlFor="del-avail">
                                                    This availability
                                                </label>
                                            </div>
                                            <div className="del-reccurence-slots">
                                                <input
                                                    type="radio"
                                                    id="del-rec"
                                                    name="delete-rec"
                                                    onChange={() =>
                                                        setDeleteReccurence({
                                                            ...deleteReccurence,
                                                            recurrence: true,
                                                        })
                                                    }
                                                />
                                                <label htmlFor="del-rec">
                                                    This and following
                                                    availability
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className="modal-footer mt-4">
                                <button
                                    type="button"
                                    className="btn btn-popup"
                                    onClick={() => {
                                        setShowDelete(false)
                                        setDeleteModal(
                                            (isDeleteModal) => !isDeleteModal,
                                        )
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-100 btn-primary"
                                    onClick={deleteSubmit}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </section>
    )
}

export { Availability }
